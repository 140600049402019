import { Paper, Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { DashContext } from "../DashContext";
import CircularProgress from "@mui/material/CircularProgress";
import PieChart from "./PieChart";
import Table from "./Table";
import Dropdown from "react-bootstrap/Dropdown";

// import SendSms from "./SendSms";

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.secondary,
	// height: 60,
	lineHeight: "60px",
}));

const darkTheme = createTheme({ palette: { mode: "dark" } });
// const lightTheme = createTheme({ palette: { mode: 'light' } });

export default function Dashboard(props) {
	const { getIncome, income, isLoading } = useContext(DashContext);
	const [dataType, setDataType] = useState("default");

	const [data, setData] = useState([]);

	const loadIncome = () => {
		getIncome();
		setData(income);
	};

	useEffect(() => {
		loadIncome();
	}, []);

	return (
		<div className="container mt-5" style={{ width: "100%" }}>
			<Grid
				container
				spacing={2}
				className="d-flex"
				style={{ justifyContent: "center" }}
			>
				{/* <SendSms /> */}
				<Grid item xs={12}>
					<ThemeProvider theme={darkTheme}>
						<Box
							sx={{
								p: 2,
								borderRadius: 2,
								bgcolor: "background.default",
								display: "grid",
								gridTemplateColumns: { md: "1fr 1fr" },
								gap: 2,
							}}
						>
							<Item elevation={3} sx={{ p: 2 }}>
								<h3>TOTAL SALE</h3>
								{!isLoading ? (
									<h1>${income.income?.total_amount}</h1>
								) : (
									<CircularProgress color="success" />
								)}
							</Item>
							<Item elevation={3} sx={{ p: 2 }}>
								<h3>ITEM SOLD</h3>
								{!isLoading ? (
									<h1>{income.income?.total_qt}</h1>
								) : (
									<CircularProgress color="success" />
								)}
							</Item>
							<Item elevation={3} sx={{ p: 2 }}>
								<h3>COMPLETED ORDERS</h3>
								{!isLoading ? (
									<h1>{income.income?.total_orders}</h1>
								) : (
									<CircularProgress color="success" />
								)}
							</Item>
							<Item elevation={3} sx={{ p: 2 }}>
								<h3>BEST SELLING PRODUCT</h3>
								{!isLoading ? (
									<h3>
										{income.bestSellingItem?.title} x{" "}
										{income?.bestSellingItem?.total_quantity_sold}
									</h3>
								) : (
									<CircularProgress color="success" />
								)}
							</Item>
						</Box>

						<Box
							sx={{
								p: 2,
								borderRadius: 2,
								bgcolor: "background.default",
								display: "grid",
								gap: 2,
							}}
						>
							<Dropdown align="end">
								<Dropdown.Toggle
									variant="success"
									id="dropdown-basic"
									style={{ width: "200px" }}
								>
									{dataType === "default" ? "Data Type Filter" : dataType}
								</Dropdown.Toggle>
								<Dropdown.Menu style={{ width: "200px" }}>
									<Dropdown.Item onClick={() => setDataType("default")}>
										Default
									</Dropdown.Item>
									<Dropdown.Item onClick={() => setDataType("+30days")}>
										Have order in 30 days
									</Dropdown.Item>
									<Dropdown.Item onClick={() => setDataType("-30days")}>
										No order in 30 days
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							{dataType === "default" && <Table data={income.userPoints} />}

							{dataType === "+30days" && (
								<Table data={income.userHaveOrderIn30days} />
							)}

							{dataType === "-30days" && (
								<Table data={income.noOrderFor30days} />
							)}
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
		</div>
	);
}
