import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { url } from '../url';
import LoadingBar from './LoadingBar';

export default function Report(props) {
    const { currentUser } = props;
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [data, setData] = useState([])
    const [totalSale, setTotalSale] = useState(0)
    const [totalTax, setTotalTax] = useState(0)
    const [totalSubtotal, setTotalSubTotal] = useState(0)
    const [totalQt, setTotalQt] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const mo = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    const getReport = async (year, month) => {
        setIsLoading(true)
        if (year && month) { // Corrected the condition check
            try {
                const response = await axios.get(url + '/get_report', {
                    params: { year, month },
                });
                setData(response.data);

                const taxs = response.data.reduce((acc, cur) => {
                    return parseFloat(acc) + parseFloat(cur.total_sales_tax);
                }, 0);

                const total = response.data.reduce((acc, cur) => {
                    return parseFloat(acc) + parseFloat(cur.total_total);
                }, 0);

                const subtotal = response.data.reduce((acc, cur) => {
                    return parseFloat(acc) + parseFloat(cur.total_subtotal);
                }, 0);
                const qt = response.data.reduce((acc, cur) => {
                    return parseInt(acc) + parseInt(cur.total_qt);
                }, 0);

                setTotalQt(qt)
                setTotalSubTotal(subtotal)
                setTotalSale(total)
                setTotalTax(taxs);
            } catch (error) {
                console.error('Error fetching report:', error);
            }
            setIsLoading(false)
        } else {
            alert('Data required');
            window.location.reload()
        }
    };

    const handleSelectedYear = (e) => {
        const selectedYear = e.target.value
        setYear(selectedYear)
    }

    const handleSelectedMonth = (e) => {
        const selectedMonth = e.target.value
        setMonth(selectedMonth)
    }

    if (isLoading) {
        return <LoadingBar />
    }

    return (
        <div className='container' style={{ color: 'white' }}>
            <div>
                <select defaultValue={year} onChange={handleSelectedYear} style={{ width: '100px', backgroundColor: '#094100', border: 'none', color: 'white', height: '30px', textAlign: 'center', margin: '15px' }}>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                </select>
                <select defaultValue={year} onChange={handleSelectedMonth} style={{ width: '100px', backgroundColor: '#094100', border: 'none', color: 'white', height: '30px', textAlign: 'center', margin: '15px' }}>
                    {
                        mo.map((el, index) => {
                            return <option value={el} key={index}>{el}</option>
                        })
                    }

                </select>
            </div>
            <button onClick={() => getReport(year, month)} style={{ width: '100px', backgroundColor: '#094100', border: 'none', color: 'white', height: '30px', textAlign: 'center', margin: '15px', borderRadius: '5px' }}>Generate</button>
            <div style={{ width: '100%' }}>
                {
                    year !== null && month !== null ? <h3>
                        {year + ' / ' + month}
                    </h3> : <></>
                }

                <table style={{ width: '100%', marginTop: '28px' }}>
                    <thead >
                        <tr style={{ backgroundColor: '#094100', border: '1px solid green' }}>
                            <th style={{ borderRight: '1px solid white' }}>Title</th>
                            <th style={{ borderRight: '1px solid white' }}>QT</th>
                            <th style={{ borderRight: '1px solid white' }}>Sales Tax</th>
                            <th style={{ borderRight: '1px solid white' }}>subtotal</th>
                            <th>total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length > 0 ? data.map((el, index) => {
                                return <tr key={index} style={{ border: '1px solid gray' }}>
                                    <td style={{ borderRight: '1px solid white' }}>{el.title ? el.title.toUpperCase() : '*PRODUCT DELETED'}</td>
                                    <td style={{ borderRight: '1px solid white' }}>{el.total_qt}</td>
                                    <td style={{ borderRight: '1px solid white' }}>{el.total_sales_tax}</td>
                                    <td style={{ borderRight: '1px solid white' }}>{el.total_subtotal}</td>
                                    <td>{el.total_total}</td>
                                </tr>
                            }) : <tr><td colSpan="100%" style={{ textAlign: 'center', padding: '20px' }}>No data found</td></tr>
                        }
                        {
                            data.length > 0 ?
                                <>
                                    <tr style={{ backgroundColor: '#094100', border: '1px solid green' }}>
                                        <td style={{ fontWeight: 'bold', borderRight: '1px solid white' }}>TOTAL</td>
                                        <td style={{ fontWeight: 'bold', borderRight: '1px solid white' }}>{totalQt}</td>
                                        <td style={{ fontWeight: 'bold', borderRight: '1px solid white' }}>{totalTax}</td>
                                        <td style={{ fontWeight: 'bold', borderRight: '1px solid white' }}>{totalSubtotal}</td>
                                        <td style={{ fontWeight: 'bold' }}>{totalSale}</td>
                                    </tr>
                                </> : <></>
                        }
                    </tbody>
                </table>
            </div>
        </div >
    );
}