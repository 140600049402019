import { React, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function UsePointsTable(props) {
	const { data } = props;
	const navigate = useNavigate();

	// State to manage selected emails
	const [selectedEmails, setSelectedEmails] = useState([]);

	// Function to handle selecting or deselecting all emails
	const handleSelectAll = (event) => {
		if (event.target.checked) {
			// Selecting all emails
			const allEmails = data.map((el, index) => ({
				index: index,
				email: el.email,
				name: `${el.firstName} ${el.lastName}`,
			}));
			setSelectedEmails(allEmails);
		} else {
			// Deselect all
			setSelectedEmails([]);
		}
	};

	// Function to handle selecting individual emails
	const handleSelectEmail = (emailObj) => {
		const isSelected = selectedEmails.some(
			(selected) => selected.email === emailObj.email
		);

		if (isSelected) {
			// Remove email if already selected
			setSelectedEmails(
				selectedEmails.filter((selected) => selected.email !== emailObj.email)
			);
		} else {
			// Add the email object to the selectedEmails array
			setSelectedEmails([...selectedEmails, emailObj]);
		}
	};

	// Function to navigate and pass selected emails
	const email = () => {
		const dataToSend = selectedEmails;
		// Navigate to the target route and pass the data as state
		navigate("/email", { state: { selectedEmails: dataToSend } });
	};

	// Check if all emails are selected
	const isAllSelected =
		data?.length > 0 && selectedEmails?.length === data.length;

	return (
		<TableContainer
			component={Paper}
			sx={{ marginBottom: "50px", paddingBottom: "50px;" }}
		>
			<div
				style={{
					margin: "15px 20px",
					// backgroundColor: "rgb(9, 65, 0)",
					padding: "15px",
				}}
			>
				{selectedEmails.length + " emails selected "}
				<Button
					variant="contained"
					onClick={email}
					disabled={!selectedEmails.length}
					sx={{ marginLeft: "30px !important" }}
				>
					SEND
				</Button>
			</div>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell padding="checkbox" align="center">
							<Checkbox
								checked={isAllSelected}
								onChange={handleSelectAll}
								indeterminate={
									selectedEmails.length > 0 &&
									selectedEmails.length < data.length
								}
								inputProps={{ "aria-label": "select all emails" }}
							/>
						</TableCell>
						<TableCell align="center">First Name</TableCell>
						<TableCell align="center">Last Name</TableCell>
						<TableCell align="center">Phone</TableCell>
						<TableCell align="center">Email</TableCell>
						<TableCell align="center">Points</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data?.map((el, index) => (
						<TableRow
							key={index}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TableCell padding="checkbox" align="center">
								<Checkbox
									checked={selectedEmails.some(
										(selected) => selected.email === el.email
									)}
									onChange={() =>
										handleSelectEmail({
											index,
											email: el.email,
											name: `${el.firstName} `,
										})
									}
									inputProps={{ "aria-label": `select email ${el.email}` }}
								/>
							</TableCell>
							<TableCell align="center">{el.firstName.toUpperCase()}</TableCell>
							<TableCell align="center">{el.lastName.toUpperCase()}</TableCell>
							<TableCell align="center">{el.phone}</TableCell>
							<TableCell align="center">{el.email.toUpperCase()}</TableCell>
							<TableCell align="center">{el.points}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
