import { React, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { url } from "../url";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoin, faChrome } from "@fortawesome/free-brands-svg-icons";
import { Paper, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function Profile(props) {
	const { currentUser } = props;
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState(currentUser.data.firstName);
	const [lastName, setLastName] = useState(currentUser.data.lastName);
	const [email, setEmail] = useState(currentUser.data.email);
	const [address, setAddress] = useState(currentUser.data.address);
	const [city, setCity] = useState(currentUser.data.city);
	const [state, setState] = useState(currentUser.data.state);
	const [zipcode, setZipcode] = useState(currentUser.data.zipcode);
	const [phone, setPhone] = useState(currentUser.data.phone);

	const updateUser = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("firstName", firstName);
		formData.append("lastName", lastName);
		formData.append("email", email);
		formData.append("address", address);
		formData.append("city", city);
		formData.append("state", state);
		formData.append("zipcode", zipcode);
		formData.append("phone", phone);
		formData.append("id", currentUser.data.userId);

		axios.put(url + "/updateUserById", formData).then((err, response) => {
			alert("Profile updated!");
			navigate("/");
		});
	};

	const theme = createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundColor: "black", // Customize the background color
						color: "white",
					},
				},
			},
		},

		shadows: ["none", "0px 3px 4px 2px rgba(9, 65, 0, 0.5)"], // Customize the elevation color
	});

	return (
		<ThemeProvider theme={theme}>
			<div className="container">
				<Paper sx={{ margin: "25px 0", padding: "15px" }} elevation={1}>
					<FontAwesomeIcon
						icon={faChrome}
						style={{
							color: "orange",
							fontSize: "25px",
							margin: "0 15px",
						}}
					></FontAwesomeIcon>
					<span
						style={{
							margin: "auto",
							textAlign: "center",
							fontSize: "25px",
							color: "orange",
						}}
					>
						{currentUser.data.points + " pt"}
					</span>
					<span style={{ display: "block", margin: "15px", fontWeight: "500" }}>
						Referral:{" "}
						<span style={{ margin: "0 5px" }}>
							{currentUser.data.referralCode}
						</span>
					</span>
				</Paper>
				<Paper elevation={1}>
					<Form style={{ width: "80%", margin: "auto" }}>
						<Row className="mb-3" style={{ paddingTop: "15px" }}>
							<Form.Group as={Col} controlId="formGridFirstName">
								<Form.Label>First Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="First Name"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</Form.Group>
							<Form.Group as={Col} controlId="formGridLastName">
								<Form.Label>Last Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Last Name"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</Form.Group>
						</Row>
						<Row className="mb-3">
							<Form.Group as={Col} controlId="formGridEmail">
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="email"
									placeholder="Enter email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Form.Group>

							<Form.Group as={Col} controlId="formGridPassword">
								<Form.Label>Password</Form.Label>
								<Form.Control type="password" placeholder="Password" disabled />
							</Form.Group>
						</Row>
						<Form.Group className="mb-3" controlId="formGridAddress1">
							<Form.Label>Address</Form.Label>
							<Form.Control
								placeholder="Address"
								value={address}
								onChange={(e) => setAddress(e.target.value)}
							/>
						</Form.Group>
						<Row className="mb-3">
							<Form.Group as={Col} controlId="formGridCity">
								<Form.Label>City</Form.Label>
								<Form.Control
									value={city}
									onChange={(e) => setCity(e.target.value)}
								/>
							</Form.Group>

							<Form.Group as={Col} controlId="formGridState">
								<Form.Label>State</Form.Label>
								<Form.Control
									value={state}
									onChange={(e) => setState(e.target.value)}
								/>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="formGridZip">
								<Form.Label>Zip</Form.Label>
								<Form.Control
									value={zipcode}
									onChange={(e) => setZipcode(e.target.value)}
								/>
							</Form.Group>
							<Form.Group as={Col} controlId="formGridPhone">
								<Form.Label>Phone</Form.Label>
								<Form.Control
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</Form.Group>
						</Row>
						<Button
							className="m-5"
							variant="contained"
							type="submit"
							onClick={updateUser}
							style={{
								backgroundColor: "#094100",
								border: "none",
								width: "200px",
								margin: "auto",
							}}
						>
							Submit
						</Button>
					</Form>
				</Paper>
			</div>
		</ThemeProvider>
	);
}
